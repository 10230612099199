import dayjs from 'dayjs';
const getInitialState = () => {
    return {
        // Version 1 Context
        plannerDetails: null,
        unformattedTestDate: null,
        formattedTestDate: null,
        goToSelectDate: false,

        // Version 2 Context
        plannerV2Details: [],
        currentUserWeek: null,
        userSelectedWeek: null, // To differentiate between active week and user selected week in current view
        focusSubjectId: null,
        focusSubjectName: null,
        isEditable: null,
        pageTitle: null,
        testDate: null,
        allowPlannerEdit: null,
        incompleteBannerStatus: false,

        // First View
        showPreview: false
    };
};

export const state = () => ({
    ...getInitialState()
});

export const actions = {
    async getPlannerData({ state, commit }, payload) {
        const response = {};
        try {
            const { data } = await this.$api.priorityPlanner.getPlannerData(payload);
            response.state = data.state;
            if (data.state) {
                commit('setPlannerDetails', data.data.planner);
                commit('setUnformattedTestDate', data.data.test_date);
                commit('setFormattedTestDate', data.data.test_date_formatted);
            }
        } catch (err) {
            response.state = false;
        }
        return response;
    },
    async getPlannerDataV2({ commit }, payload) {
        const response = {};
        try {
            const { data } = await this.$api.priorityPlanner.getPlannerDataV2(payload);
            response.state = data.state;
            if (data.state) {
                commit(
                    'setPlannerV2Details',
                    data.data.planner.sort((prevWeek, nextWeek) => {
                        return prevWeek.week - nextWeek.week;
                    })
                );
                commit('setCurrentWeek', data.data.current_week_number);
                commit('setFocusSubjectName', data.data.focus_subject_name);
                commit('setIsEditable', data.data.is_editable);
                commit('setDate', dayjs(data.data.test_date).format('MMMM D, YYYY'));
                commit('setUnformattedTestDate', data.data.test_date);
            }
        } catch (err) {
            response.state = false;
        }
        return response;
    },
    async updateTaskStatus({ state, commit }, payload) {
        try {
            await this.$api.priorityPlanner.updateTaskStatus(payload);

            const { data } = await this.$api.priorityPlanner.getPlannerDataV2();
            if (data.state) {
                commit(
                    'setPlannerV2Details',
                    data.data.planner.sort((prevWeek, nextWeek) => {
                        return prevWeek.week - nextWeek.week;
                    })
                );
            }
        } catch (err) {
            console.log(err);
        }
    }
};

export const mutations = {
    setPlannerDetails(state, value) {
        state.plannerDetails = value;
    },
    setUnformattedTestDate(state, value) {
        state.unformattedTestDate = value;
    },
    setFormattedTestDate(state, value) {
        state.formattedTestDate = value;
    },
    setGoToSelectDate(state, value) {
        state.goToSelectDate = value;
    },
    resetState(state) {
        Object.assign(state, getInitialState());
    },
    setPlannerV2Details(state, value) {
        state.plannerV2Details = value;
    },
    setCurrentWeek(state, value) {
        state.currentUserWeek = value;
    },
    setFocusSubjectName(state, value) {
        state.focusSubjectName = value;
    },
    setIsEditable(state, value) {
        state.isEditable = value;
    },
    setDate(state, value) {
        state.testDate = value;
    },
    setAllowPlannerEdit(state, value) {
        state.allowPlannerEdit = value;
    },
    setShowPlannerPreview(state, value) {
        state.showPreview = value;
    },
    setUserSelectedWeek(state, value) {
        state.userSelectedWeek = value;
    },
    setIncompleteBannerStatus(state, value) {
        state.incompleteBannerStatus = value;
    }
};

export const getters = {
    plannerDetails(state) {
        return state.plannerDetails;
    },
    unformattedTestDate(state) {
        return state.unformattedTestDate;
    },
    formattedTestDate(state) {
        return state.formattedTestDate;
    },
    goToSelectDate(state) {
        return state.goToSelectDate;
    },
    plannerV2Details(state) {
        return state.plannerV2Details;
    },
    currentWeek(state) {
        return state.currentUserWeek;
    },
    focusSubjectName(state) {
        return state.focusSubjectName;
    },
    isEditable(state) {
        return state.isEditable;
    },
    testDate(state) {
        return state.testDate;
    },
    allowPlannerEdit(state) {
        return state.allowPlannerEdit;
    },
    showPreview(state) {
        return state.showPreview;
    },
    userSelectedWeek(state) {
        return state.userSelectedWeek;
    },
    incompleteBannerStatus(state) {
        return state.incompleteBannerStatus;
    }
};
