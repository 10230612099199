export const state = () => ({
    isSideBarOpen: false,
    isModalOpen: false
});

export const mutations = {
    setSideBarStatus(state, value) {
        state.isSideBarOpen = value;
        // Apply the overflow properties only if a modal is not already open
        if (!state.isModalOpen)
            if (value) {
                document.body.style.overflow = 'hidden';
            } else {
                document.body.style.overflow = 'auto';
            }
    },
    setIsModalOpen(state, value) {
        state.isModalOpen = value;
        // Apply overflow hidden if any modal is open and remove it on close
        if (value) {
            document.body.style.overflow = 'hidden';
        } else {
            document.body.style.overflow = 'auto';
        }
    },
    triggerTour(state, { tour, tourName, startDelay = 2000 }) {
        setTimeout(() => {
            tour[tourName].start();
        }, startDelay);
    }
};

export const getters = {
    getSideBarStatus(state) {
        return state.isSideBarOpen;
    },
    isModalOpen(state) {
        return state.isModalOpen;
    },
    showPlansPageBanner: () => {
        return process.env.SHOW_PLANS_PAGE_BANNER === 'true';
    }
};
