const getInitialState = () => {
    return {
        // Modals state
        isExitTestModalOpen: false,
        isTimesUpModalOpen: false,
        isFinishTestModalOpen: false,
        isFinishSectionModalOpen: false,
        isPauseAndSaveModalOpen: false,
        isFastSkippingModalOpen: false,

        // Current test details
        currTestTypeKey: '', // Test type identifier
        currTestTakerId: null,
        isTestPausable: false,
        currTestSetId: null,
        testSectionInstanceId: null, // Instance ID of the test section
        currTestSections: [], // All the section in the current selected test

        // Current section details
        currSectionOrdering: 1, // The ordering number of the currentsection out of all sections, eg: Verbal 1
        currSection: {}, // The current section details
        currSectionDuration: 0, // The time duration of the current section
        currSectionQuestions: [], // All the questions in the current section
        timeInterval: null,
        allAnswers: {}, // All the selected answers
        lastRemainingTime: 0,
        currRemainingTime: 0,
        timesUp: false,

        // Question details
        currQuestionIndex: 0, // The index of the current question
        currQuestionsIndex: 0, // Index of the array of the current question - current question can have 2 questions if its a diagnosic test
        currQuestion: {}, // The current question which is being displayed
        currQuestionValidation: {
            isValid: true,
            validationMessage: ''
        },
        payload: {}, // payload for submitting the answer,
        currQuestionStatus: {
            isSubmitted: false,
            isCorrect: false
        },

        // UI management
        canChangeRoute: true, // To determine if the route can be changed at any instace or not
        selectedQuestionsTab: {
            id: 1,
            title: 'Passage'
        },
        showAnswersDuringTest: false,
        consecutiveCorrectAnswers: 0,

        // question/solutions feedback
        feedbackResourceTypes: null,
        feedbacks: []
    };
};

export const state = () => ({
    ...getInitialState()
});

export const actions = {
    /* STEP-1 => Fetch all the test section details (no questions data) based on the testSetId */
    async getTestSections({ commit }, id) {
        const response = {};
        try {
            const {
                data: { data }
            } = await this.$api.testSets.getTestSetUsingId(id);
            const sections = data?.testType?.testSections;
            commit('setCurrTestSections', sections);
        } catch (error) {
            this.$nuxt.error({ statusCode: 500 });
            response.state = false;
        }
        return response;
    },

    /*
        STEP-2 => Fetch the test section data
        1. If it is a new test it assigns a testTakerId to the test.
        2. Fetch the section details i.e., questions,duration and other metadata
    */
    async getTestSetData({ commit }, payload) {
        const response = {};
        try {
            const testSetId = payload.test_set_id;
            delete payload.test_set_id;
            const {
                data: { data, state }
            } = await this.$api.questionSets.getNewSectionQuestions(testSetId, payload);
            response.state = state;
            if (state) {
                commit('setCurrTestTakerId', data.test_taker_id);
                commit('setCurrTestSectionQuestions', data.questions);
                commit('setCurrentSectionDuration', data.total_duration);
                commit('setTestSectionInstanceId', data.test_section_instance_id);
            }
        } catch (error) {
            // if error while fetching 1st section then show 500 error page
            // if (!payload.test_taker_id) {
            //     this.$nuxt.error({ statusCode: 500 });
            // }
            response.state = false;
            response.errorMessage = error.response?.data?.errors?.message || '';
        }
        return response;
    },

    /* 
        It will set the interval on currSectionDuration state variable. 
        Initially the value is set to the section's duration.
    */
    startTimer({ state, commit }) {
        commit('clearTimer');
        let timeonPageLeave = 0;
        let leftDate = null;

        const interval = setInterval(() => {
            // If the tab becomes inactive we store the current remaining duration on which the user left,
            // We also store the last date at which the user left inside leftDate variable
            // when the user comes back new date is generated (currDate)
            // and then we get the inactive time of user on the tab by (currDate - leftDate) / 1000
            // Because the timer doesnt get slowed down before 5 mins so we are checking if atleast 3 mins have passed.
            // if three mins have passed then we update the curr time duration by timeonPageLeave - inactiveTimeInSeconds
            let inactiveTimeInSeconds = 0;

            if (document.hidden && !leftDate) {
                timeonPageLeave = state.currRemainingTime;
                leftDate = new Date();
            } else if (!document.hidden && leftDate) {
                const currDate = new Date();
                inactiveTimeInSeconds = (currDate - leftDate) / 1000;
                if (inactiveTimeInSeconds <= 180) {
                    leftDate = null;
                    timeonPageLeave = 0;
                }
            }

            if (inactiveTimeInSeconds > 180) {
                commit('setCurrRemainingTime', timeonPageLeave - inactiveTimeInSeconds);
                leftDate = null;
                timeonPageLeave = 0;
            } else {
                commit('setCurrRemainingTime', state.currRemainingTime - 1);
            }
        }, 1000);

        commit('setTimeInterval', interval);
    },

    async sendAnswer({ state, commit }, payload = {}) {
        try {
            const isLastQuestion =
                state.currQuestionIndex === state.currSectionQuestions.length - 1;
            const testTakerId = state.currTestTakerId;
            const questionId = state.currQuestion.question_id;
            commit('createPayload', { ...payload, isLastQuestion });
            const { data } = await this.$api.answers.submitAnswer(
                testTakerId,
                questionId,
                state.payload
            );
            if (data.state) {
                const currQuestionStatus = {
                    isSubmitted: true,
                    isCorrect: data.data.is_correct,
                    correctAnswers: data.data.correct_answers,
                    markedAnswers: state.allAnswers[state.currQuestionIndex],
                    timeTaken: data.data.time_taken,
                    statistics: {
                        ...data.data.question_statistics,
                        time_taken: data.data.time_taken
                    },
                    hasTestExpired: data.data.is_expired,
                    nextDifficulty: data.data.is_correct ? 3 : 1
                };
                commit('setCurrQuestionStatus', currQuestionStatus);

                if ('isCorrect' in currQuestionStatus) {
                    commit('setConsecutiveCorrectAnswers', currQuestionStatus.isCorrect);
                }
            } else {
                commit('resetCurrQuestionStatus');
            }
            commit('setLastRemainingTime', state.currRemainingTime);
        } catch (error) {
            commit('resetAnswer');
            commit('resetCurrQuestionStatus');
        }
    },

    async pauseAndSave({ dispatch, commit, state }, payload) {
        const resumeFromCurrQues = payload.resumeFromCurrQues;
        const response = {};
        try {
            if (!payload.dontSendAnswer) {
                await dispatch('sendAnswer');
            }
            const testTakerId = state.currTestTakerId;
            const testSectionInstanceId = state.testSectionInstanceId;
            const questionId = state.currQuestion.question_id;

            let res;
            if (resumeFromCurrQues) {
                res = await this.$api.answers.pauseAndSaveTest(testTakerId, testSectionInstanceId, {
                    question_id: questionId
                });
            } else {
                res = await this.$api.answers.pauseAndSaveTest(testTakerId, testSectionInstanceId);
            }
            const { data } = res;

            response.state = data.state;
        } catch (error) {
            response.state = false;
        }
        return response;
    },

    async getResumeTestData({ dispatch, commit }, payload) {
        const response = {};
        try {
            const {
                data: { data, state }
            } = await this.$api.questionSets.getResumedSectionQuestions(payload.testTakerId);
            commit('setTestPausable', true);
            if (!data.questions) {
                await dispatch('restoreNextSection', { ...payload, data });
            }
            // if resuming an ongoing section
            else {
                dispatch('restoreCurrentSection', { ...payload, data });
            }
            response.state = state;
        } catch (error) {
            response.state = false;
        }
        return response;
    },

    restoreCurrentSection({ commit }, { data }) {
        commit('setCurrTestTakerId', data.test_taker_id);
        commit('setCurrentSectionDuration', data.total_duration ?? null);
        commit('setTestSectionInstanceId', data.test_section_instance_id);
        commit('setCurrSectionOrdering', data.current_section_ordering);
        commit('setCurrTestSectionQuestions', data.questions);
        commit('setCurrentQuestionIndex', { index: data.last_question_ordering - 1 });
        commit('setAllAnswers', data.test_session_responses);
    },

    async restoreNextSection({ dispatch, commit, state }, { data }) {
        commit('setCurrSectionOrdering', data.last_section_ordering + 1);
        const currSection = state.currTestSections.find(
            (item) => item.ordering === data.last_section_ordering + 1
        );
        const payload = {
            is_timed: data.is_timed,
            test_section_key: currSection.test_section_key,
            test_set_id: state.currTestSetId,
            test_taker_id: data.test_taker_id
        };
        await dispatch('getTestSetData', payload);
        commit('setCurrentQuestionIndex', { index: 0 });
    },
    async exitTest({ commit, state }) {
        const response = {};
        try {
            const { data } = await this.$api.answers.exitTest(
                state.currTestTakerId,
                state.testSectionInstanceId
            );
            commit('setCurrentTestSetId', null);
            response.state = data.state;
        } catch (error) {
            response.state = false;
        }
        return response;
    },
    async sectionTimeExpired({ state }) {
        const response = {};
        try {
            const { data } = await this.$api.answers.exitSectionOnTimerExpiry(
                state.currTestTakerId,
                state.testSectionInstanceId
            );
            response.state = data.state;
        } catch (error) {
            response.state = false;
        }
        return response;
    }
};

export const mutations = {
    setExitTestModal(state, value) {
        state.isExitTestModalOpen = value;
    },
    setTimesUpModal(state, value) {
        // closing all other modals if time is up
        if (value) {
            state.isExitTestModalOpen = false;
            state.isFinishTestModalOpen = false;
            state.isFinishSectionModalOpen = false;
            state.isPauseAndSaveModalOpen = false;
        }
        // setting times up modal
        state.isTimesUpModalOpen = value;
    },
    setFinishTestModal(state, value) {
        state.isFinishTestModalOpen = value;
    },
    setFinishSectionModal(state, value) {
        state.isFinishSectionModalOpen = value;
    },
    setPauseAndSaveModalOpen(state, value) {
        state.isPauseAndSaveModalOpen = value;
    },
    setFastSkippingModal(state, value) {
        state.isFastSkippingModalOpen = value;
    },
    setTestPausable(state, value) {
        state.isTestPausable = value;
    },
    setCurrTestTakerId(state, value) {
        state.currTestTakerId = value;
    },
    setCurrTestSections(state, value) {
        state.currTestSections = value;
    },
    resetAllAnswers(state) {
        state.allAnswers = {};
    },
    setAllAnswers(state, value) {
        state.allAnswers = value;
    },
    setTimesUp(state, value) {
        state.timesUp = value;
    },
    setCanChangeRoute(state, value) {
        state.canChangeRoute = value;
    },
    setCurrTestType(state, value) {
        state.currTestTypeKey = value;
    },
    setShowAnswersDuringTest(state, value) {
        state.showAnswersDuringTest = value;
    },
    setSelectedQuestionsTab(state, value) {
        state.selectedQuestionsTab = value;
    },
    setLastRemainingTime(state, value) {
        state.lastRemainingTime = value;
    },
    setCurrRemainingTime(state, value) {
        state.currRemainingTime = value;
    },
    setCurrTestSectionQuestions(state, value) {
        state.currSectionQuestions = value;
    },
    storeCurrentAnswer(state, payload) {
        const { answer, type, index } = payload;
        if (
            type === 'singleselect' ||
            type === 'multiselect' ||
            type === 'text' ||
            type === 'passage_sentence'
        ) {
            state.allAnswers[index] = answer;
        } else if (type.includes('blank')) {
            if (index in state.allAnswers) {
                state.allAnswers[index][type] = answer;
            } else {
                const blankAnswer = {
                    blank_1: null,
                    blank_2: null,
                    blank_3: null
                };
                if (state.currQuestion.input_type === 'blank_1') {
                    delete blankAnswer.blank_2;
                    delete blankAnswer.blank_3;
                }
                if (state.currQuestion.input_type === 'blank_2') {
                    delete blankAnswer.blank_3;
                }
                blankAnswer[type] = answer;
                state.allAnswers[index] = blankAnswer;
            }
        } else if (type.includes('fraction')) {
            if (index in state.allAnswers) {
                state.allAnswers[index][type] = answer;
            } else {
                const fractionAnswer = {
                    fraction_numerator: null,
                    fraction_denominator: null
                };
                fractionAnswer[type] = answer;
                state.allAnswers[index] = fractionAnswer;
            }
        }
    },

    validateAnswer(state, type) {
        const quesIndex = state.currQuestionIndex;
        if (quesIndex in state.allAnswers) {
            if (type.includes('fraction')) {
                const currIndexAnswer = state.allAnswers[quesIndex];
                const numerator = currIndexAnswer['fraction_numerator'];
                const denominator = currIndexAnswer['fraction_denominator'];
                const condition = (!numerator || !denominator) && !(!numerator && !denominator);
                if (condition) {
                    state.currQuestionValidation.isValid = false;
                    state.currQuestionValidation.validationMessage = 'Please enter both values';
                } else {
                    state.currQuestionValidation.isValid = true;
                    state.currQuestionValidation.validationMessage = '';
                }
            }
        }
    },
    // Reset answer on frontend which gave api error
    resetAnswer(state) {
        delete state.allAnswers[state.currQuestionIndex];
    },
    setCurrentQuestionIndex(state, value) {
        // setting current question index
        state.currQuestionIndex = value.index;
        if (
            state.currTestTypeKey.includes('diagnostic') &&
            !value.previous &&
            value.index % 2 !== 0
        ) {
            const nextIndex = state.currSectionQuestions[value.index].findIndex((item) => {
                return item.difficulty === value.nextDifficulty;
            });
            state.currQuestionsIndex = nextIndex;
            state.currQuestion = state.currSectionQuestions[value.index][nextIndex];
        } else {
            // setting current question
            state.currQuestion = state.currSectionQuestions[value.index][0];
            state.currQuestionsIndex = 0;
        }
    },
    setCurrSectionOrdering(state, value) {
        // setting current section ordering
        state.currSectionOrdering = value;

        // setting current section
        state.currSection = state.currTestSections.find((item) => item.ordering === value);
    },
    setCurrentSectionDuration(state, value) {
        state.currSectionDuration = value;
    },
    setTestSectionInstanceId(state, value) {
        state.testSectionInstanceId = value;
    },
    setCurrentTestSetId(state, value) {
        state.currTestSetId = value;
    },
    setTimeInterval(state, value) {
        state.timeInterval = value;
    },
    clearTimer(state) {
        clearInterval(state.timeInterval);
    },
    createPayload(state, { isLastQuestion, goToPrevQues = false }) {
        const testSectionInstanceId = state.testSectionInstanceId;
        const quesIndex = state.currQuestionIndex;
        const timeTaken = state.lastRemainingTime - state.currRemainingTime;
        let status = 1;

        // Get current question type (singleselect, multiselect, etc)
        const currQuesType =
            state.currSectionQuestions[quesIndex][state.currQuestionsIndex].input_type;
        // Check if it exists inside all answers
        if (quesIndex in state.allAnswers) {
            if (currQuesType === 'mcq_m') {
                status = state.allAnswers[quesIndex].length > 0 ? 1 : 2;
            }
            if (currQuesType === 'mcq_s') {
                status = state.allAnswers[quesIndex] ? 1 : 2;
            }
            if (
                currQuesType === 'numeric' ||
                currQuesType === 'percent' ||
                currQuesType === 'passage_sentence'
            ) {
                status = state.allAnswers[quesIndex] && state.allAnswers[quesIndex] !== '' ? 1 : 2;
            }
            if (currQuesType === 'fraction') {
                status =
                    state.allAnswers[quesIndex] &&
                    state.allAnswers[quesIndex]['fraction_numerator'] &&
                    state.allAnswers[quesIndex]['fraction_denominator']
                        ? 1
                        : 2;
            }
        } else {
            status = 2;
        }

        let answerOption = [];
        const answerBlank1 = [];
        const answerBlank2 = [];
        const answerBlank3 = [];
        let value = '';
        let value2 = '';

        // Get the answers id/ids in answer_option
        if (quesIndex in state.allAnswers) {
            const currIndexAnswer = state.allAnswers[quesIndex];
            if (currQuesType === 'mcq_m') {
                answerOption = currIndexAnswer.map((item) => item.answer_id);
            }
            if (currQuesType === 'mcq_s') {
                answerOption.push(currIndexAnswer.answer_id);
            }
            // checking for blank answers
            if (currQuesType.includes('blank')) {
                if ('blank_1' in currIndexAnswer && currIndexAnswer['blank_1']) {
                    answerBlank1.push(currIndexAnswer['blank_1'].answer_id);
                }
                if ('blank_2' in currIndexAnswer && currIndexAnswer['blank_2']) {
                    answerBlank2.push(currIndexAnswer['blank_2'].answer_id);
                }
                if ('blank_3' in currIndexAnswer && currIndexAnswer['blank_3']) {
                    answerBlank3.push(currIndexAnswer['blank_3'].answer_id);
                }
            }
            if (
                currQuesType === 'numeric' ||
                currQuesType === 'percent' ||
                currQuesType === 'passage_sentence'
            ) {
                value = currIndexAnswer;
            }
            if (currQuesType === 'fraction') {
                value = currIndexAnswer['fraction_numerator'];
                value2 = currIndexAnswer['fraction_denominator'];
            }
        }
        state.payload = {
            test_section_instance_id: testSectionInstanceId,
            status,
            answer_option: answerOption.length > 0 ? answerOption : undefined,
            answer_blank_1: answerBlank1.length > 0 ? answerBlank1 : undefined,
            answer_blank_2: answerBlank2.length > 0 ? answerBlank2 : undefined,
            answer_blank_3: answerBlank3.length > 0 ? answerBlank3 : undefined,
            value: value && value.length ? value : undefined,
            value_2: value2 && value.length ? value2 : undefined,
            is_complete: isLastQuestion,
            time_taken: Math.floor(timeTaken)
        };

        // Condition for sending is_complete
        if (
            (isLastQuestion && state.timesUp) ||
            !isLastQuestion ||
            state.isPauseAndSaveModalOpen ||
            goToPrevQues
        ) {
            delete state.payload.is_complete;
        }
    },
    setCurrQuestionStatus(state, value) {
        state.currQuestionStatus = value;
    },
    resetCurrQuestionStatus(state) {
        state.currQuestionStatus = {
            isSubmitted: false,
            isCorrect: false
        };
    },
    setConsecutiveCorrectAnswers(state, isCorrect) {
        state.consecutiveCorrectAnswers = isCorrect ? state.consecutiveCorrectAnswers + 1 : 0;
    },
    resetState(state) {
        Object.assign(state, getInitialState());
    },
    updateFeedback(state, { questionId, body }) {
        const feedbackIndex = state.feedbacks.findIndex(
            (feedback) => feedback.question_id === questionId
        );

        const feedBackObject = {
            question_id: questionId,
            description: body.description,
            feedback_resource_type_id: body.feedback_resource_type,
            feedback_status_id: 1
        };

        if (feedbackIndex < 0) {
            state.feedbacks.push(feedBackObject);
        } else {
            state.feedbacks[feedbackIndex] = feedBackObject;
        }
    },
    setFeedbackResourceTypes(state, payload) {
        state.feedbackResourceTypes = payload;
    }
};

export const getters = {
    isExitTestModalOpen(state) {
        return state.isExitTestModalOpen;
    },
    isTimesUpModalOpen(state) {
        return state.isTimesUpModalOpen;
    },
    isFinishTestModalOpen(state) {
        return state.isFinishTestModalOpen;
    },
    isFinishSectionModalOpen(state) {
        return state.isFinishSectionModalOpen;
    },
    isPauseAndSaveModalOpen(state) {
        return state.isPauseAndSaveModalOpen;
    },
    isFastSkippingModalOpen(state) {
        return state.isFastSkippingModalOpen;
    },
    isTestPausable(state) {
        return state.isTestPausable;
    },
    currTestTakerId(state) {
        return state.currTestTakerId;
    },
    currTestSetId(state) {
        return state.currTestSetId;
    },
    allAnswers(state) {
        return state.allAnswers;
    },
    timesUp(state) {
        return state.timesUp;
    },
    canChangeRoute(state) {
        return state.canChangeRoute;
    },
    currTestTypeKey(state) {
        return state.currTestTypeKey;
    },
    showAnswersDuringTest(state) {
        return state.showAnswersDuringTest;
    },
    selectedQuestionsTab(state) {
        return state.selectedQuestionsTab;
    },
    currTestSections(state) {
        return state.currTestSections;
    },
    currSectionOrdering(state) {
        return state.currSectionOrdering;
    },
    currSection(state) {
        return state.currSection;
    },
    currSectionQuestions(state) {
        return state.currSectionQuestions;
    },
    currSectionDuration(state) {
        return state.currSectionDuration;
    },
    currQuestionIndex(state) {
        return state.currQuestionIndex;
    },
    currQuestionsIndex(state) {
        return state.currQuestionsIndex;
    },
    currQuestion(state) {
        return state.currQuestion;
    },
    currQuestionValidation(state) {
        return state.currQuestionValidation;
    },
    currentAnswer(state) {
        return state.currentAnswer;
    },
    currRemainingTime(state) {
        return state.currRemainingTime;
    },
    currQuestionStatus(state) {
        return state.currQuestionStatus;
    },
    showAnswer(state) {
        return state.showAnswersDuringTest && state.currQuestionStatus.isSubmitted;
    },
    consecutiveCorrectAnswers(state) {
        return state.consecutiveCorrectAnswers;
    },
    feedbacks(state) {
        return state.feedbacks;
    },
    feedbackResourceTypes(state) {
        return state.feedbackResourceTypes;
    }
};
