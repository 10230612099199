import dayjs from 'dayjs';
import localizableFormat from 'dayjs-ext/plugin/localizableFormat';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';

import { LIVE_CLASS_DURATIONS } from '~/constants/premiumPlans';

dayjs.extend(localizableFormat);
dayjs.extend(utc);
dayjs.extend(timezone);

export const state = () => ({
    liveClasses: null,
    upcomingClasses: null,
    pastClasses: null,
    upcomingBatch: {
        startDate: '__ ___ ',
        endDate: '__ ___ ',
        duration: '2 Months',
        timings: '8:00 - 9:30 PM IST'
    }
});

export const actions = {
    async getClasses({ state, commit }) {
        const response = { state: true };
        try {
            const {
                data: { data }
            } = await this.$api.liveClasses.getClasses();

            commit('setLiveClasses', data?.live || null);
            commit('setUpcomingClasses', data?.upcoming || null);
            commit('setPastClasses', data?.past || null);
        } catch (error) {
            response.state = false;
        }
        return response;
    }
};

export const mutations = {
    setLiveClasses(state, value) {
        state.liveClasses = value;
    },
    setUpcomingBatchDetails(state, value) {
        const timeZone = dayjs.tz.guess();
        const startDate = dayjs(value.start_date).tz(timeZone);
        const endDate = dayjs(value.end_date).tz(timeZone);
        // const duration = `${endDate.diff(startDate, 'month')} Months`;
        const duration = LIVE_CLASS_DURATIONS.COURSE_DURATION;
        const timings = LIVE_CLASS_DURATIONS.TIMINGS;

        const obj = {
            startDate: startDate.format('D MMM'),
            endDate: endDate.format('D MMM'),
            duration,
            timings
        };

        state.upcomingBatch = obj;
    },
    setUpcomingClasses(state, value) {
        state.upcomingClasses = value;
    },
    setPastClasses(state, value) {
        state.pastClasses = value;
    },
    setQuantLiveClasses(state, value) {
        state.quantLiveClasses = value;
    },
    setVerbalLiveClasses(state, value) {
        state.verbalLiveClasses = value;
    },
    setQuantUpcomingClasses(state, value) {
        state.quantUpcomingClasses = value;
    },
    setVerbalUpcomingClasses(state, value) {
        state.verbalUpcomingClasses = value;
    },
    setQuantPastClasses(state, value) {
        state.quantPastClasses = value;
    },
    setVerbalPastClasses(state, value) {
        state.verbalPastClasses = value;
    }
};

export const getters = {
    liveClasses(state) {
        return state.liveClasses;
    },
    upcomingClasses(state) {
        return state.upcomingClasses;
    },
    pastClasses(state) {
        return state.pastClasses;
    },
    upcomingBatch(state) {
        return state.upcomingBatch;
    }
};
