import { TEST_TYPES } from '~/static/constants.js';

export const state = () => ({
    allTests: [],
    activeTest: {},
    showInstructions: false,
    quickDiagnosticData: null,
    fullDiagnosticData: null,
    fetchingTest: false
});

export const actions = {
    async fetchDiagnosticTestData({ state, commit }, diagnosticTestType) {
        const response = {
            state: false
        };

        if (state.fetchingTest) {
            return response;
        }

        try {
            const queryParams = {
                test_type_keys: ''
            };

            if (diagnosticTestType === 'QD')
                queryParams.test_type_keys = `${TEST_TYPES.QUICK_DIAGNOSTIC}`;
            else if (diagnosticTestType === 'FD')
                queryParams.test_type_keys = `${TEST_TYPES.FULL_DIAGNOSTIC}`;

            commit('setFetchingTest', true);
            const { data } = await this.$api.testSets.getTestSets(queryParams);

            response.state = data.state;

            if (data.state) {
                const payload = {
                    diagnosticTestType,
                    diagnosticTestData: data.data[0]
                };
                commit('setDiagnosticTestData', payload);
            }
        } catch (error) {
            response.state = false;
        } finally {
            commit('setFetchingTest', false);
        }
        return response;
    }
};

export const mutations = {
    setAllTests(state, value) {
        state.allTests = value;
    },
    setActiveTest(state, value) {
        state.activeTest = value;
    },
    setShowInstructions(state, value) {
        state.showInstructions = value;
    },
    setFetchingTest(state, value) {
        state.fetchingTest = value;
    },
    setDiagnosticTestData(state, { diagnosticTestType, diagnosticTestData }) {
        if (diagnosticTestType === 'QD') {
            state.quickDiagnosticData = diagnosticTestData;
        } else if (diagnosticTestType === 'FD') {
            state.fullDiagnosticData = diagnosticTestData;
        }
    }
};

export const getters = {
    allTests(state) {
        return state.allTests;
    },
    activeTest(state) {
        return state.activeTest;
    },
    showInstructions(state) {
        return state.showInstructions;
    },
    quickDiagnosticData(state) {
        return state.quickDiagnosticData;
    },
    fullDiagnosticData(state) {
        return state.fullDiagnosticData;
    }
};
