import { trackEvent, trackLogin } from '~/services/analyticsTracker';
const getInitialState = () => {
    return {
        trackWebEngage: true,
        trackGA: true,
        trustpilotScriptAdded: false
    };
};

export const state = () => ({
    ...getInitialState()
});

export const actions = {
    trackEvent({ commit, rootGetters }, payload) {
        try {
            // Fetching default attributes to be sent with every event.
            const isUserAuthenticated = rootGetters['auth/isAuthenticated'];
            const isUserPremium = rootGetters['auth/isPremiumUser'];
            const defaultTrackingAttributes = {
                is_logged_in: isUserAuthenticated,
                is_premium: isUserPremium,
                platform_id: 1
            };
            /**  Checking if corresponding key exists in analyticEvents file
             * and adding it to trackingPayload as per jsonSchemaValidator.validateAnalyticsSchema().
             */
            const eventName = payload.event;
            const events = {};
            if (eventName.GA) {
                events.GA = eventName.GA;
            }
            if (eventName.WE) {
                events.WE = eventName.WE;
            }
            const trackingPayload = {};
            trackingPayload.events = events;

            // Add default attributes to existing data attributes, if any.
            if (payload.data) {
                trackingPayload.data = { ...payload.data, ...defaultTrackingAttributes };
            } else {
                trackingPayload.data = defaultTrackingAttributes;
            }
            commit('trackEvent', trackingPayload);
        } catch (error) {
            console.error('Event Payload Error!');
        }
    }
};

export const mutations = {
    trackOnboarding(state, value) {
        trackLogin();
    },
    trackEvent({ state, rootState }, trackingPayload) {
        trackEvent(trackingPayload);
    },
    setTrustpilotScriptAdded(state, value) {
        state.trustpilotScriptAdded = value;
    }
};

export const getters = {
    trackWebEngage: (state) => state.trackWebEngage,
    trackGA: (state) => state.trackGA,
    trustpilotScriptAdded: (state) => state.trustpilotScriptAdded
};
